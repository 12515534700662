<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  computed: {
    ...mapGetters({
      freeId: "content/freeId",
    }),
  },
});
</script>

<template>
  <a
    v-if="freeId > 0"
    :href="'search?query=chatgpt AI AI4NE&subscription=' + freeId"
    class="block bg-blue-dark px-6 py-4 text-center text-16 leading-[1.2] -tracking-[0.0275rem] text-white-lightest hover:underline"
    active-class="underline"
    ><strong class="font-thicker"
      >Free AI training and tutorials from Cisco U.</strong
    >
    From entry to expert, gain real-world skills to succeed in AI.</a
  >
  <span
    v-else
    class="block bg-blue-dark px-6 py-4 text-center text-16 leading-[1.2] -tracking-[0.0275rem] text-white-lightest"
    ><strong class="font-thicker"
      >Free AI training and tutorials from Cisco U.</strong
    >
    From entry to expert, gain real-world skills to succeed in AI.</span
  >
</template>
