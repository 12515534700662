import http from "@/services/http";
import { EventBus } from "@/store/eventBus";

const productError =
  "There was an error adding the item to your cart. Please try again!";

export async function add(email: string, productId: string) {
  const { data } = await http().post(
    window.env.COMMERCE_API + "/commerce/basket",
    {
      product: {
        customerInfo: {
          email: email,
        },
        productItems: [
          {
            productId: productId,
            quantity: 1,
          },
        ],
      },
      learner: {
        productId: productId,
        quantity: 1,
        c_learnerEmails: "{" + email + "}",
      },
    }
  );
  return data;
}

export async function getBasket(basketId: string) {
  const { data } = await http().get(
    window.env.COMMERCE_API + "/commerce/basket/" + basketId
  );
  calculateCartItem(data);
  return data;
}

async function calculateCartItem(data: any) {
  let counter = 0;
  if (data && data.basketId != "" && data.productItems) {
    data.productItems.forEach(function (product: any) {
      if (product) {
        counter += product.quantity;
      }
    });
  }
  EventBus.emit("updateQty", counter);
}

export async function addToCart(productId: string, quantity: number) {
  const basketId = localStorage.getItem("BASKET_ID");
  await http()
    .post(window.env.COMMERCE_API + "/commerce/basket/products", {
      basket: {
        basketId: basketId,
      },
      products: [
        {
          productId: productId,
          quantity: quantity,
        },
      ],
    })
    .then((data: any) => {
      calculateCartItem(data.data);
      const productItems = data.data.productItems.map(
        (item: any, index: number) => {
          return {
            productId: item.productId,
            itemId: item.itemId,
            productTitle: item.itemText,
            productPrice: item.price,
            basePrice: item.basePrice,
            productQuantity: item.quantity,
            "line-num": index,
          };
        }
      );

      EventBus.emit("cartTelemetry", {
        productItems,
        basketId: data.data.basketId,
      });

      EventBus.emit("showBanner", {
        type: "SUCCESS",
        message: "The item has been successfully added to your shopping cart",
      });
    })
    .catch((error: any) => {
      console.log("addToCart  - ", error);
      EventBus.emit("showBanner", {
        type: "ERROR",
        message: productError,
      });
    });
}

export async function updateProduct(productId: string, quantity: number) {
  const basketId = localStorage.getItem("BASKET_ID");
  const { data } = await http().post(
    window.env.COMMERCE_API + "/commerce/basket/products",
    {
      basket: {
        basketId: basketId,
      },
      products: [
        {
          productId: productId,
          quantity: quantity,
        },
      ],
    }
  );
  return data;
}

export async function validateAndAddProduct(
  productId: string,
  quantity: number,
  email: string
) {
  const basketId = localStorage.getItem("BASKET_ID");
  if (basketId != "" && basketId != null && basketId != "NA") {
    await getBasket(basketId)
      .then((data: any) => {
        if (data && data.basketId != "" && basketId == data.basketId) {
          localStorage.setItem("BASKET_ID", data.basketId);
          return validateCTFProduct(data, productId, quantity);
        } else {
          return createBasket(productId, quantity);
        }
      })
      .catch((error: any) => {
        if (
          ["error while reading the token", "clear the cart"].includes(
            error?.response?.data?.error
          )
        ) {
          return createBasket(productId, quantity);
        }
        EventBus.emit("showBanner", {
          type: "ERROR",
          message: productError,
        });
        return error;
      });
  } else {
    return createBasket(productId, quantity);
  }
}

function validateCTFProduct(data: any, productId: string, quantity: number) {
  if (data && data.productItems) {
    let ctfProductFound = false;
    data.productItems.forEach(function (product: any) {
      if (
        product.productId == productId &&
        product.c_product_type == "CTF" &&
        product.quantity > 0
      ) {
        ctfProductFound = true;
      }
    });
    if (!ctfProductFound) {
      return addToCart(productId, quantity);
    } else {
      EventBus.emit("showBanner", {
        type: "ERROR",
        message: "You cannot purchase multiple quantity of this product.",
      });
    }
  } else {
    return addToCart(productId, quantity);
  }
}

async function createBasket(productId: string, quantity: number) {
  await http()
    .get(window.env.COMMERCE_API + "/commerce/login")
    .then((data: any) => {
      if (data.data && data.data.basketId != "") {
        localStorage.setItem("BASKET_ID", data.data.basketId);
        return validateCTFProduct(data, productId, quantity);
      }
    })
    .catch((error: any) => {
      console.log("createBasket - ", error);
      EventBus.emit("showBanner", {
        type: "ERROR",
        message: productError,
      });
      return error;
    });
}

export async function addLearnerInformation(learners: any) {
  const basketId = localStorage.getItem("BASKET_ID");
  const { data } = await http().post(
    window.env.COMMERCE_API + "/commerce/basket/learners",
    {
      basket: {
        basketId: basketId,
      },
      learners: learners,
    }
  );
  return data;
}

export async function removeProduct(itemId: string) {
  const basketId = localStorage.getItem("BASKET_ID");
  const { data } = await http().post(
    window.env.COMMERCE_API + "/commerce/basket/remove-product",
    {
      basket: {
        basketId: basketId,
      },
      itemId: itemId,
    }
  );
  calculateCartItem(data);
  return data;
}

export async function updateProductLineItem(lineItem: any) {
  const basketId = localStorage.getItem("BASKET_ID");
  const { data } = await http().post(
    window.env.COMMERCE_API + "/commerce/basket/update-product",
    {
      basket: {
        basketId: basketId,
      },
      lineItem: lineItem,
    }
  );
  calculateCartItem(data);
  return data;
}

export async function loginUser() {
  const { data } = await http().get(
    window.env.COMMERCE_API + "/commerce/login"
  );
  calculateCartItem(data);
  return data;
}

export async function applyDiscountCoupon(coupon: any) {
  const basketId = localStorage.getItem("BASKET_ID");
  const { data } = await http().post(
    window.env.COMMERCE_API + "/commerce/basket/apply-coupon",
    {
      basket: {
        basketId: basketId,
      },
      coupon: coupon,
    }
  );
  return data;
}

export async function removeDiscountCoupon(itemId: any) {
  const basketId = localStorage.getItem("BASKET_ID");
  const { data } = await http().post(
    window.env.COMMERCE_API + "/commerce/basket/remove-coupon",
    {
      basket: {
        basketId: basketId,
      },
      itemId: itemId,
    }
  );
  return data;
}

export async function validateBasket(basketId: string) {
  const { data } = await http().get(
    window.env.COMMERCE_API + "/commerce/validate/basket/" + basketId
  );
  if (data && data.status == "VALID") {
    calculateCartItem(data.basket);
  }
  return data;
}

export async function orderDetails(orderId: string) {
  const { data } = await http().get(
    window.env.COMMERCE_API + "/commerce/order-details/" + orderId
  );
  return data;
}
